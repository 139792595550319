<template>
  <div class="main__content profile__wrapper">
    <template v-if="!!structure">
      <div
        v-for="(subgroup, id_0) in structure"
        :key="subgroup.uuid"
        :style="{ order: subgroup.order_position }"
      >
        <h4 class="title-h4 profile__title" v-if="!!subgroup.title">
          {{ subgroup.title }}
        </h4>
        <div
          v-for="(group, id_1) in subgroup.subgroups"
          :key="group.uuid"
          class="report__block"
          :class="{ 'report__sub-block': !!group.level }"
          :style="{ order: group.order_position }"
        >
          <h5 class="title-h5 profile__title" v-if="!!group.title">
            {{ group.title }}
          </h5>

          <div class="report__grid">
            <div
              v-for="(item, id_2) in group.criterias"
              :key="item.uuid"
              :id="item.uuid"
              :class="[`${item.classnames}`]"
              :style="{ order: item.order_position }"
            >
              <!-- {{ item.uuid }} -->
              <template v-if="item.type === 'select'">
                <SelectField
                  v-model="item.value"
                  :items="item.options"
                  :uuid="item.uuid"
                  titleKey="title"
                  valueToReturn="object"
                  :title="item.title"
                  :is-required="item.validation.includes('required')"
                  :is-error="
                    $v.structure.$each[id_0].subgroups.$each[id_1].criterias
                      .$each[id_2].$error
                  "
                  :is-disabled="
                    editIsDisabled(item.uuid) || !!item.sum_of.length
                  "
                  placeholder=""
                  comment-to="report"
                  :error-text="$t(`error.required_field`)"
                  :is-commentable="commentsVisible"
                  :can-comment="canComment"
                  isMultiSelect
                  isObjectType
                />
              </template>
              <template v-else>
                <text-field
                  :key="item.uuid"
                  :uuid="item.uuid"
                  v-model="item.value"
                  placeholder="-"
                  :title="item.title"
                  :value-mask="item.mask"
                  :error-text="$t(`error.required_field`)"
                  :bold-report="false"
                  :no-underline="
                    !!item && !!item.sum_of && !!item.sum_of.length
                  "
                  :is-required="item.validation.includes('required')"
                  :is-inactive="
                    editIsDisabled(item.uuid) ||
                    (!!item.sum_of && !!item.sum_of.length)
                  "
                  :is-error="
                    $v.structure.$each[id_0].subgroups.$each[id_1].criterias
                      .$each[id_2].$error
                  "
                  comment-to="report"
                  :is-commentable="commentsVisible"
                  :can-comment="canComment"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <div class="control__btn-wrapper">
        <ButtonsBlock
          :buttons="buttonsArray"
          :disabledSign="!isUserHasEuKey"
          :disabledAchive="!isAchive"
          @print="print()"
          @saveAsDraft="saveDraftHandler"
          @signAndSend="signAndSendReportHendler"
          @downloadPDF="downloadPDF()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import { getTourOperatorReportStructure } from '@/api/services/tour_operator';
import LoaderContent from '@/elements/LoaderContent.vue';
import SelectField from '@/elements/SelectField.vue';
import ButtonsBlock from '@/elements/ButtonsBlock.vue';
import model from '@/models/tour_operator/report';
import userData from '@/models/tour_operator/user_data';

export default {
  components: { LoaderContent, SelectField, ButtonsBlock },

  data() {
    return {
      structure: null,
      canEdit: '',
      canComment: '',
      commentsVisible: false,
      buttonsArray: [],
    };
  },

  async created() {
    await this.reportStrucure();
    this.$store.commit('SET_REPORTS_DATA', null);
    this.$store.commit('SET_REPORTS_COMMENTS', null);

    if (this.isEditReport || this.isViewReport) {
      await this.$store.dispatch('getReportsData', {
        report_uuid: this.$route.params.id,
      });
      await this.setReportData();
      await this.$store.dispatch('getReportsComments', this.$route.params.id);
    } else {
      this.setUserData();
    }

    await this.statusModelHandler();
  },

  watch: {
    isReportCreate(val) {
      val && this.$router.push({ name: 'tour-operator_reports-list' });
      this.$store.commit('SET_REPORT_DRAFT_SAVED', false);
    },

    structure: {
      handler(arr) {
        arr.map((g, i) =>
          g.subgroups.map((s) =>
            s.criterias.map((c) => {
              if (!!c?.sum_of?.length) {
                let sum = 0;
                c.sum_of.map((k) => {
                  sum += !!this.fieldsObjForCalculate[k]
                    ? this.fieldsObjForCalculate[k]
                    : 0;
                });
                c.value = `${sum}`;
              }
              // else {
              //   //==== temp
              //   if (i > 0) {
              //     c.value = '100';
              //   }
              // }
            })
          )
        );
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('modal', ['getModalState']),
    ...mapGetters([
      'getAuthUserData',
      'getReportsData',
      'getReportsComments',
      'isReportDraftSaved',
      'isUserHasEuKey',
    ]),

    isReportCreate() {
      return !this.getModalState && this.isReportDraftSaved;
    },

    isEditReport() {
      return !!this.$route.params.id && this.$route.params.activity === 'edit';
    },

    isViewReport() {
      return (
        !!this.$route.params.id && this.$route.params.activity === 'readonly'
      );
    },

    fieldsObjForCalculate() {
      let obj = {};

      this.structure?.map((g) =>
        g.subgroups.map((s) =>
          s.criterias.map((c) => {
            if (c.validation.includes('int')) {
              obj[c.uuid] = !!c.value
                ? parseInt(c.value.split(' ').join(''))
                : 0;
            }
          })
        )
      );

      return obj;
    },

    currentButtons() {
      let res = model.find((el) => {
        if (!!this.getReportsData) {
          return (
            el.filing_status === this.getReportsData.filing_status &&
            el.review_status === this.getReportsData.review_status
          );
        } else {
          return el.status === 'default';
        }
      });
      return res?.buttons;
    },

    getDataForSave() {
      let criterias = new FormData();
      let idx = 0;
      this.structure.map((g) =>
        g.subgroups.map((s) =>
          s.criterias.map((c) => {
            let val = c.value;
            let isValueWas = c.uuid in this.reportsDataObj;
            let isValueRemoved =
              isValueWas &&
              !Boolean(c.value?.length) &&
              !Boolean(Object.keys(c?.value)?.length);

            if (isValueRemoved) {
              criterias.append(
                `delete_criterias[]`,
                this.reportsDataObj[c.uuid].uuid
              );
            } else if (!!val) {
              if (c.validation.includes('int')) {
                val = parseInt(c.value.split(' ').join(''));
              }
              if (c.type === 'select') {
                criterias.append(`criterias[${idx}][uuid]`, c.uuid);
                c.value.map((s) => {
                  criterias.append(`criterias[${idx}][value][]`, s.uuid);
                });
              } else if (!!val) {
                criterias.append(`criterias[${idx}][uuid]`, c.uuid);
                criterias.append(`criterias[${idx}][value]`, val);
              }

              idx++;
            }
          })
        )
      );
      return criterias;
    },

    errorFieldId() {
      let res;
      this.structure.find((el) =>
        el.subgroups.find((s) =>
          s.criterias.find((c) => {
            if (c.validation.includes('required') && !Boolean(c.value)) {
              return (res = c.uuid);
            }
          })
        )
      );

      return res;
    },

    reportsDataObj() {
      let obj = {};
      if (!!this.getReportsData) {
        this.getReportsData?.criteria.map((el) => {
          obj[el.criterion_uuid] = {
            value: el.value,
            uuid: el.uuid,
          };
        });
      }
      return obj;
    },

    isAchive() {
      return !!this.getReportsData?.archive;
    },
  },

  methods: {
    async reportStrucure() {
      let data = { lang: 'uk' };
      if (this.$route.params.activity !== 'create' && !!this.$route.params.id) {
        data.uuid = this.$route.params.id;
      }
      let res;
      try {
        res = await getTourOperatorReportStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log('>>> reportStrucure error', e);
      }
    },

    setUserData() {
      this.structure?.map((g) =>
        g.subgroups.map((s) =>
          s.criterias.map((c) => {
            let key = userData[c.uuid];
            if (!!this.getAuthUserData) {
              let val = this.getAuthUserData[key];
              if (key in this.getAuthUserData) {
                if (key === 'district') {
                  val = this.getAuthUserData.district?.public_name;
                } else if (key === 'state') {
                  val = this.getAuthUserData.state?.public_name;
                } else if (key === 'settlement') {
                  val = this.getAuthUserData.settlement?.public_name;
                }
              }
              c.value = !!val ? `${val}` : val;
            }
          })
        )
      );
    },

    setReportData() {
      this.structure?.map((g) =>
        g?.subgroups?.map((s) =>
          s.criterias?.map((c) => {
            if (c.uuid in this.reportsDataObj) {
              if (c.type === 'select') {
                let selected = this.reportsDataObj[c.uuid].value.map((el) => {
                  return c.options.find(
                    (s) => s.uuid === el.criterion_select_option_uuid
                  );
                });
                c.value = selected;
              } else {
                c.value = this.reportsDataObj[c.uuid]?.value;
              }
            }
          })
        )
      );
    },

    statusModelHandler() {
      model.forEach((element) => {
        if (
          element.filing_status == this.getReportsData?.filing_status &&
          element.review_status == this.getReportsData?.review_status
        ) {
          this.canEdit = element.edit;
          this.canComment = element.can_comment;
          this.commentsVisible = element.comments_visible;
          this.buttonsArray = [...element.buttons];
        }
      });
    },

    editIsDisabled(uuid) {
      let editIsDisabled = false;
      switch (this.canEdit) {
        case 'can_edit_all_fields':
          editIsDisabled = false;
          break;
        case 'not_allowed':
          editIsDisabled = true;
          break;
        case 'can_edit_commented_fields':
          this.getReportsComments[uuid]
            ? (editIsDisabled = false)
            : (editIsDisabled = true);
          break;
      }
      return editIsDisabled;
    },

    saveDraftHandler() {
      if (this.isEditReport) {
        // this.getDataForSave.report_uuid = this.$route.params.id;
        this.getDataForSave.append('report_uuid', this.$route.params.id);
        this.$store.dispatch('editReportAsDraft', {
          data: this.getDataForSave,
        });
      } else {
        this.$store.dispatch('saveReportAsDraft', {
          data: this.getDataForSave,
        });
      }
    },

    signAndSendReportHendler() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        document
          .getElementById(this.errorFieldId)
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        if (this.isEditReport) {
          this.getDataForSave.append('report_uuid', this.$route.params.id);
          this.$store.commit('SET_DATA_TO_SIGN', this.$route.params.id);
          this.$store.dispatch('editReportAsDraft', {
            data: this.getDataForSave,
            isSign: true,
          });
        } else {
          this.$store.dispatch('saveReportAsDraft', {
            data: this.getDataForSave,
            isSign: true,
          });
        }
      }
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getReportsData.archive.original_url
      );
    },

    print() {
      window.print();
    },
  },

  validations() {
    return {
      structure: {
        $each: {
          subgroups: {
            $each: {
              criterias: {
                $each: {
                  value: {
                    required: requiredIf((value) => {
                      return value.validation.includes('required');
                    }),
                  },
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>

export default {
  '265b1eab-ab87-4974-8131-ee2bf839f47c': 'name_of_legal_entity',
  'ba124315-2093-488d-8b9f-e88f501cc6e7': null, //phone
  'ed706bfc-152f-4189-a776-2f7389f2c061': null, //fax
  'b91dab3d-ec9d-40d0-a0d1-a2794f1544d4': 'head_full_name',
  '3667c43a-b29c-4408-8f0b-3e68afa250b4': 'email',
  'df055d32-ebf4-4d20-9643-4f5dcd8c2020': 'code_main',
  'f843d7bc-c5bd-4102-8585-55bd9eaedeba': 'house_type',
  '6fe325c5-cd58-4715-9279-a2529fb8b015': 'house_number',
  '72527c59-50f4-48ca-9090-a01c8d6f69e0': 'building_type',
  '225284a8-b060-45a9-8632-dce71f12655c': 'building_number',
  'c7c0f607-a877-485b-9930-da11e4b633f2': 'street',
  '2718011d-19d1-4a4a-b59e-1b00ef31eb35': 'settlement', // public_name
  'c6134ca0-0646-46ee-a872-1d4f07ff4be9': 'district', // public_name
  '43f371be-25c1-4b48-996a-cc93d176aee8': 'state', // public_name
  'f919269c-8dd8-4fa4-8cea-ec6f16cb2e30': 'zip',
  'ba38286b-c1eb-4f6e-b830-156f00e42e90': 'location_of_the_activity',
  '24dbcfeb-7873-48e8-8225-eb66d72a3c36': 'type_of_activity',
};
